
// mat-table
.mdc-data-table__header-cell {
  font-size: 0.8rem; 
}

.mat-mdc-table .mat-mdc-header-cell {
  text-align: left;
  vertical-align: middle;
  font-weight: 600;
  background-color: var(--dg-light-gray) !important;
  border: .5px solid var(--dg-border-dark);
  padding: .5rem;
  border-spacing: 0;
  color: var(--dg-font);
  font-family: var(--font-text);
}

.mat-mdc-table {
  border-collapse: separate; 
  line-height: 1.25;
  border-spacing: 0;
}

.mdc-data-table__cell {
  font-family: var(--font-text);
  font-weight: 400;
  border: .5px solid var(--dg-border);
}

.mdc-data-table__table {
  border: .5px solid var(--dg-border) !important;
}

.required .mdc-text-field--outlined {
  border: 1px solid var(--red);
  background-color: rgb(255, 235, 235);
}

.mat-mdc-footer-cell {
  border: .5px solid var(--dg-border-dark) !important;
}

.mdc-data-table__cell {
  font-size: 0.8rem !important;
}

.mat-mdc-row:nth-child(even) {
  background-color: var(--dg-white);  /* your color for even rows */
}

.mat-mdc-row:nth-child(odd) {
  background-color: var(--dg-light-white);  /* your color for odd rows */
}

.mdc-text-field__input {
  font-family: var(--font-text);
  font-weight: 400;
  font-size: 14px !important;
  margin: 0px;
}

.mdc-text-field--outlined {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.mdc-text-field {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.mdc-text-field--disabled.mdc-text-field--filled {
  background-color: inherit;
}

.mat-mdc-row:hover {
  background-color: var(--dg-light-blue) !important; /* Light gray */
}

table .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

table .mat-mdc-form-field-infix {
  min-height: 5px !important;
}

table .mdc-data-table__cell {
  padding-left: .70rem;
  padding-right: .70rem;
  padding-top: 0;
  padding-bottom: 0;
}

.form-grid table .mdc-text-field__input {
  font-size: .8rem !important;
}

.mdc-notched-outline__leading {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.mdc-notched-outline__trailing {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

//mat-select
.table-x mat-form-field.mat-mdc-form-field.mat-mdc-form-field-type-mat-select {
  width: 100%;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: inherit !important;
}

//mat-tab
.mdc-tab__text-label {
  font-weight: 800;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: var(--blue-dark) !important;
}

.searchbar .mdc-text-field--outlined .mdc-notched-outline {
  --mdc-outlined-text-field-outline-width: 0;
  --mdc-outlined-text-field-focus-outline-width: 0;
}

//mat-select label 
label.mat-mdc-floating-label  {
  top: 50% !important;
}

.mat-stepper-horizontal-line {
    opacity: 0.3;
}