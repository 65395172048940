/*
 * Chart legend
 */
 .charts-css {

  &.legend {
    padding: 1rem;
    border: var(--legend-border-width) var(--legend-border-style) var(--legend-border-color);
    list-style: none;

    font-size: 1rem;

    li {
      line-height: 2;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-inline-end: .5rem;
        border-width: 2px;
        border-style: solid;
      }

      @for $i from 1 through $total-colors {

        &:nth-child(#{ $i })::before {
          background-color: var(--color-#{ $i }, transparent);
          border-color: var(--border-color-#{ $i }, var(--border-color, #000));
        }

      }
    }
  }

  // Legend Alignment
  &:not(.legend-inline) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
  &.legend-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      margin-inline-end: 1rem;
    }
  }

  // Circle Label
  &.legend-circle {
    li::before {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
  }

  // Ellipse Label
  &.legend-ellipse {
    li::before {
      width: 2rem;
      height: 1rem;
      border-radius: 50%;
    }
  }

  // Square Label
  &.legend-square {
    li::before {
      width: 1rem;
      height: 1rem;
      border-radius: 3px;
    }
  }

  // Rhombus Label
  &.legend-rhombus {
    li::before {
      width: 1rem;
      height: 1rem;
      border-radius: 3px;
      transform: rotate(45deg) scale(.85);
    }
  }

  // Rectangle Label
  &.legend-rectangle {
    li::before {
      width: 2rem;
      height: 1rem;
      border-radius: 3px;
    }
  }

  // Line Label
  &.legend-line {
    li::before {
      width: 2rem;
      height: 3px;
      border-radius: 2px;
      box-sizing: content-box;
    }
  }

}