/******************************************************************************
    Icons
******************************************************************************/
@font-face {
	font-family:'ChevronIcons3';
	src:url("https://cdn-nextpage-test.azure.chevron.com/3.0.0/fonts/ChevronIcons.eot?qg7vaw");
	src:url("https://cdn-nextpage-test.azure.chevron.com/3.0.0/fonts/ChevronIcons.eot?qg7vaw#iefix") format("embedded-opentype"),url("https://cdn-nextpage-test.azure.chevron.com/3.0.0/fonts/ChevronIcons.ttf?qg7vaw") format("truetype"),url("https://cdn-nextpage-test.azure.chevron.com/3.0.0/fonts/ChevronIcons.woff?qg7vaw") format("woff"),url("https://cdn-nextpage-test.azure.chevron.com/3.0.0/fonts/ChevronIcons.svg?qg7vaw#ChevronIcons") format("svg");
	font-weight:normal;
	font-style:normal;
}

// Fonts
$font-base: Arial, sans-serif;
$font-gotham: 'GothamBold', $font-base;
$font-gotham-bold: 'GothamBold', $font-base;
$font-gotham-bold-italic: 'GothamBoldItalic', $font-base;
$font-gotham-book: 'GothamBook', $font-base;
$font-gotham-book-italic: 'GothamBookItalic', $font-base;
$font-gotham-book-medium: 'GothamBookMedium', $font-base;
$font-gotham-book-medium-italic: 'GothamBookMediumItalic', $font-base;
$font-gotham-narrow-bold: 'GothamNarrowBold', $font-base;
$font-gotham-narrow-bold-italic: 'GothamNarrowBoldItalic', $font-base;
$font-gotham-narrow-book: 'GothamNarrowBook', $font-base;
$font-gotham-narrow-book-italic: 'GothamNarrowBookItalic', $font-base;
$font-gotham-narrow-medium: 'GothamNarrowMedium', $font-base;
$font-gotham-narrow-medium-italic: 'GothamNarrowMediumItalic', $font-base;
$font-icons-cvx: 'ChevronIcons';

// Brand Colors
$colors: (
    dark-blue: rgb(11, 45, 113),    medium-blue: rgb(0, 102, 178),      light-blue: rgb(0, 157, 217),
    dark-teal: rgb(0, 54, 83),      medium-teal: rgb(0, 112, 140),      light-teal: rgb(0, 178, 189),
    dark-green: rgb(68, 75, 13),    medium-green: rgb(118, 146, 49),    light-green: rgb(178, 204, 52),
    dark-red: rgb(88, 0, 28),       medium-red: rgb(151, 0, 46),        light-red: rgb(226, 24, 54),
    dark-orange: rgb(113, 27, 0),   medium-orange: rgb(229, 96, 31),    light-orange: rgb(250, 171, 24),
    dark-purple: rgb(58, 13, 54),   medium-purple: rgb(117, 21, 105),   light-purple: rgb(186, 48, 147),
    dark-gray: rgb(107, 109, 111),  medium-gray: rgb(140, 143, 147),    light-gray: rgb(219, 220, 221)
);

$color-background-gray: rgb(236, 236, 237);
$color-menu-gray: rgb(244, 244, 244);
$color-separator-gray: rgb(204, 204, 204);
$color-half-gray: rgb(127, 127, 127);
$color-table-gray: rgb(247, 247, 247);
$color-highlight-blue: rgb(228, 244, 250);
$color-tag-background-gray: rgb(239, 239, 239);
$color-tag-drop-shadow: rgba(0, 0, 0, 0.16);

// Other colors
$color-charcoal: rgb(51, 51, 51);
$color-bg-blue: rgb(224, 243, 251);
$color-yellow: rgb(255, 186, 0);
$color-black: rgb(0, 0, 0);
$color-white: rgb(255, 255, 255);

$icons: (
    home: "e98f",
    home-filled: "e990",
    email: "e907",
    email-filled: "e999",
    user: "e615",
    user-filled: "e996",
    calendar: "e905",
    calendar-filled: "e998",
    menu-hamburger: "e608",
    menu-hamburger-bold: "e987",
    trash-can: "e99b",
    trash-can-filled: "e99c",
    search: "e60f",
    search-bold: "e988",
    ellipsis: "e99d",
    ellipsis-bold: "e989",
    arrow-right: "e600",
    arrow-right-bold: "e98a",
    arrow-down: "e5fe",
    arrow-down-bold: "e98b",
    arrow-left: "e5ff",
    arrow-left-bold: "e98c",
    arrow-up: "e5fd",
    arrow-up-bold: "e98d",
    chevron-right: "e603",
    chevron-bold-right: "e902",
    chevron-down: "e601",
    chevron-bold-down: "e900",
    chevron-left: "e602",
    chevron-bold-left: "e901",
    chevron-up: "e604",
    chevron-bold-up: "e903",
    x-circle: "e915",
    x-circle-filled: "e916",
    checkmark-circle: "e917",
    checkmark-circle-filled: "e918",
    plus-circle: "e919",
    plus-circle-filled: "e91a",
    remove: "e60e",
    remove-bold: "e992",
    checkmark: "e90e",
    checkmark-bold: "e993",
    expand-plus: "e705",
    expand-plus-bold: "e994",
    expand-minus: "e704",
    expand-minus-bold: "e997",
    view-counts: "e90d",
    view-counts-filled: "e91f",
    eye-unview: "e920",
    eye-unview-filled: "e921",
    collapse-right: "e922",
    collapse-down: "e923",
    collapse-left: "e924",
    collapse-up: "e925",
    sort: "e926",
    filter: "e927",
    filter-filled: "e928",
    th: "e929",
    th-filled: "e614",
    sort-by-priority: "e92b",
    wireless-charging: "e92c",
    internet-offline: "e92d",
    globe-grid: "e700",
    cloud: "e92f",
    cloud-filled: "e930",
    cloud-offline: "e931",
    cloud-offline-filled: "e932",
    wifi: "e99e",
    download: "e934",
    upload: "e935",
    paste: "e936",
    paste-filled: "e937",
    copy: "e938",
    copy-filled: "e939",
    paperclip: "e93a",
    printer: "e93b",
    printer-filled: "e93c",
    folder: "e93d",
    folder-filled: "e93e",
    export: "e93f",
    import: "e940",
    pencil: "e941",
    pencil-filled: "e942",
    like-outline: "e707",
    like-filled: "e706",
    star: "e945",
    star-filled: "e946",
    bookmark: "e947",
    bookmark-filled: "e948",
    heart: "e949",
    heart-filled: "e94a",
    tag: "e612",
    tag-filled: "e94c",
    share-alt: "e610",
    share-alt-filled: "e94e",
    lock: "e94f",
    lock-filled: "e950",
    unlock: "e951",
    unlock-filled: "e952",
    play-triangle: "e708",
    play-circle: "e60d",
    play-circle-filled: "e955",
    pause: "e956",
    pause-circle: "e957",
    pause-circle-filled: "e958",
    skip-to-end: "e959",
    skip-to-end-circle: "e95a",
    skip-to-end-circle-filled: "e95b",
    skip-to-beginning: "e95c",
    skip-to-beginning-circle: "e95d",
    skip-to-beginning-circle-filled: "e95e",
    rewind: "e95f",
    rewind-circle: "e960",
    rewind-circle-filled: "e961",
    fast-forward: "e962",
    fast-forward-circle: "e963",
    fast-forward-circle-filled: "e964",
    closed-caption: "e965",
    closed-caption-filled: "e966",
    code: "e967",
    cog-wheel: "e968",
    cog-wheel-filled: "e969",
    new-window: "e60b",
    full-screen: "e96b",
    full-screen-exit: "e96c",
    new-tab: "e96d",
    new-tab-filled: "e96e",
    exclamation-circle: "e96f",
    exclamation-circle-filled: "e970",
    information-circle: "e90f",
    information-circle-filled: "e972",
    question-mark-circle: "e973",
    question-mark-circle-filled: "e974",
    history: "e975",
    clock: "e976",
    clock-filled: "e977",
    logout: "e978",
    notification: "e979",
    notification-filled: "e97a",
    notification-alert: "e97b",
    notification-alert-filled: "e97c",
    checklist: "e97d",
    checklist-filled: "e97e",
    checklist-new: "e97f",
    checklist-search: "e980",
    lightbulb: "e981",
    refresh: "e982",
    plugin: "e983",
    plugin-filled: "e984",
    credit-card: "e605",
    credit-card-filled: "e995",
    oil: "e60c",
    oil-filled: "e911",
    file: "e606",
    file-filled: "e913",
    comment-outline: "e703",
    comment-filled: "e702",
    media: "e616",
    media-filled: "e91e",
    mobile-phone: "e908",
    mobile-phone-filled: "e99a",
    chat: "e906",
    chat-filled: "e943",
    audio: "e985",
    no-audio: "e986",
    stats: "e611"
);

[class^="icon-"], [class*=" icon-"] {
  font-family: 'ChevronIcons' !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-right {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e600';
  }
}

.icon-arrow-right-bold {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e98a';
  }
}

.icon-remove {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e60e';
  }
}

.icon-remove-bold {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e992';
  }
}

.icon-box-transfer {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e978';
  }
}

.icon-globe-remove {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e92d';
  }
}

.icon-pencil {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e941';
  }
}

.icon-pencil-filled {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e942';
  }
}

.icon-trash-can {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e99b';
  }
}

.icon-trash-can-filled {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e99c';
  }
}

.icon-new-window {
  &:before {
    font-family: "ChevronIcons3";
    content: '\e60b';
  }
}

.icon-filter-filled::before {
  content: '\e928';
  font-family: 'ChevronIcons3' !important;
  font-size: 28px;
  color: var(--dark-blue);
}

.icon-filter-outlined::before {
  content: '\e927';
  font-family: 'ChevronIcons3' !important;
  font-size: 28px;
  color: var(--dark-blue);
}

.icon-copy {
  &:before {
    content: '\e938'; 
    font-family: "ChevronIcons3";
  }
}

.icon-upload {
  &:before {
    content: '\e935'; 
    font-family: "ChevronIcons3";
  }
}

.icon-download {
  &:before {
    content: '\e934'; 
    font-family: "ChevronIcons3";
  }
}

.icon-checkmark-circle {
  &:before {
    content: '\e917'; 
    font-family: "ChevronIcons3";
  }
}

.icon-checkmark-circle-filled {
  &:before {
    content: '\e918'; 
    font-family: "ChevronIcons3";
  }
}

.icon-expand-plus {
  &:before {
    content: '\e705'; 
    font-family: "ChevronIcons3";
  }
}

.icon-search {
  &:before {
    content: '\e60f'; 
    font-family: "ChevronIcons3";
  }
}

.icon-search-bold {
  &:before {
    content: '\e988'; 
    font-family: "ChevronIcons3";
  }
}