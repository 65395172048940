// This file was generated by running 'ng generate @angular/material:m3-theme'.
// Proceed with caution if making changes to this file.

@use 'sass:map';
@use '@angular/material' as mat;
@include mat.core();

// Note: Color palettes are generated from primary: #0b2d71, secondary: #0065b2, tertiary: #009cd9
$_palettes: (
  primary: (
    0: #000000,
    10: #001849,
    20: #092c70,
    25: #1a377b,
    30: #284387,
    35: #354f94,
    40: #0065b2,
    50: #5b74bc,
    60: #758ed7,
    70: #8fa9f4,
    80: #b3c5ff,
    90: #dbe1ff,
    95: #eef0ff,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #001c38,
    20: #00315c,
    25: #003d6e,
    30: #004882,
    35: #005495,
    40: #0060aa,
    50: #2c79c7,
    60: #4d93e3,
    70: #6caeff,
    80: #a2c9ff,
    90: #d3e4ff,
    95: #eaf1ff,
    98: #f8f9ff,
    99: #fdfcff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #001e2e,
    20: #00344c,
    25: #00405c,
    30: #004c6c,
    35: #00587d,
    40: #00658e,
    50: #007fb2,
    60: #009ad7,
    70: #3db5f4,
    80: #84cfff,
    90: #c7e7ff,
    95: #e4f3ff,
    98: #f6faff,
    99: #fbfcff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    10: #1a1b20,
    20: #2f3035,
    25: #3a3b40,
    30: #46464c,
    35: #525257,
    40: #5e5e63,
    50: #76767c,
    60: #909096,
    70: #abaab1,
    80: #c7c6cc,
    90: #e3e2e8,
    95: #f1f0f6,
    98: #ffffff,
    99: #fefbff,
    100: #ffffff,
    4: #0d0e12,
    6: #121318,
    12: #1e1f24,
    17: #292a2f,
    22: #34343a,
    24: #38393e,
    87: #dad9e0,
    92: #e9e7ee,
    94: #eeedf4,
    96: #f4f3f9,
  ),
  neutral-variant: (
    0: #000000,
    10: #191b24,
    20: #2e303a,
    25: #393b45,
    30: #0000008a,
    35: #50525c,
    40: #5c5e69,
    50: #757682,
    60: #8e909c,
    70: #a9aab6,
    80: #c4c6d2,
    90: #e1e2ee,
    95: #eff0fd,
    98: #faf8ff,
    99: #fefbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$ccms-primary: map.merge(map.get($_palettes, primary), $_rest);
$ccms-tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$ccms-theme: mat.define-theme(( 
    color: ( 
      theme-type: light, 
      primary: $ccms-primary, 
      tertiary: $ccms-tertiary,
    ),
   density: (
      scale: -3
    ),
 ));

html {
    @include mat.all-component-themes($ccms-theme);
}