.np-button {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #{map-get($colors, medium-blue)};
  outline: 0;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02px;
  padding: 8px 20px;
  text-decoration: none;
  border-bottom-style: none;
  border-radius: 0;
  min-width: 172px;
  width: auto;
  height: 48px;

  &:enabled {
      color: $color-white;
      border: 2px solid #{map-get($colors, medium-blue)};
      opacity: 1;
  }

  &:visited {
      color: #fff;

      &:after {
          color: #fff;
      }
  }

  &:hover {
      color: $color-white;
      opacity: 0.8;
  }
  
  &:disabled {
      background-color: #{map-get($colors, light-gray)};
      opacity: 1;
      color: #{map-get($colors, dark-gray)}
  }

  &::after {
      padding-left: 8px;
  }

  &:focus {
      outline: none;
      color: #{map-get($colors, $color-white)};
      opacity: 0.8;

      &:hover {
          opacity: 0.8;
      }
  }

  &.small {
      height: 32px;
      min-width: 80px;
      width: auto;
      line-height: 0;
      font-size: 14px;

      &:hover {
          color: $color-white;
          opacity: 0.8;
      }

      &:disabled {
          background-color: #{map-get($colors, light-gray)};
          opacity: 1;
          color: #{map-get($colors, dark-gray)};
          border: 0;
      }
  }

  &.button-text {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      outline: none;
      font-family: $font-gotham-bold;
      color: #{map-get($colors, medium-blue)};
      min-width: auto;
      height: auto;
    
      &:hover {
        color: #{map-get($colors, dark-blue)};
        box-shadow: inset 0 -2px 0 0 #{map-get($colors, dark-blue)};
      }
  
      &:focus {
        opacity: 0.8;
      }

      &::after {
          font-size: 10px;
          padding-left: 6px;
      }

      &:disabled {
          opacity: 1;
          color: #{map-get($colors, dark-gray)};
          border: 0;

          &:hover {
              color: #{map-get($colors, dark-gray)};
              box-shadow: none;
          }
      }
  }

  &.icon {
      min-width: 48px;
      width: 48px;
      height: 48px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      &::after {
          padding-left: 0px;
      }
  
      &.arrow-link::after {
          padding-left: 0px;
      }

      &.small {
          padding: 8px;
          height: 32px;
          width: 32px;
          min-width: 32px;
          line-height: 0;
          font-size: 14px;            
      }
  }

  &::after {
      padding-left: 8px;
  }
}

.button {
  margin: 0 auto !important;
}