/*
 * Chart colors
 */
 .charts-css {

  @for $i from 1 through $total-colors {
    &.bar tbody tr:nth-of-type(#{ $total-colors }n + #{ $i }) td,
    &.bar.multiple tbody tr td:nth-of-type(#{ $total-colors }n + #{ $i }),
    &.column tbody tr:nth-of-type(#{ $total-colors }n + #{ $i }) td,
    &.column.multiple tbody tr td:nth-of-type(#{ $total-colors }n + #{ $i }),
    &.area tbody tr td:nth-of-type(#{ $total-colors }n + #{ $i })::before,
    &.line tbody tr td:nth-of-type(#{ $total-colors }n + #{ $i })::before {
      background: var(--color, var(--color-#{ $i }));
    }

    &.pie tbody tr:nth-of-type(#{ $total-colors }n + #{ $i }) td,
    &.pie.multiple tbody tr td:nth-of-type(#{ $total-colors }n + #{ $i }) {
      --c: var(--color, var(--color-#{ $i }, transparent) );
    }
  }

}