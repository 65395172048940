.form-grid table .mat-mdc-form-field-infix {
  width: auto !important;
}

.form-grid table .mdc-text-field__input {
  text-align: left;
}

.form-grid table .mdc-data-table__cell {
  text-align: left;
}